import React from 'react';
import Layout from '../components/Layout';
import SimpleHeaderModule from '../components/SimpleHeaderModule';
import ProseModule from '../components/ProseModule';
import {Link} from 'gatsby';

const IndexPage: React.FC<{}> = () => {
  return (
    <Layout pageTitle="Miter Has Shut Down" description="Miter shut down on December 14, 2022.">
      <SimpleHeaderModule title="Miter Has Shut Down">
        As of December 2022, Miter is no longer in operation. <Link to="/old-home">View original home page &rarr;</Link>
      </SimpleHeaderModule>

      <ProseModule>
        <h3>Why did Miter shut down?</h3>
        <p>
          We set out to fix bad meetings. We believed that the right product could help motivated people improve their
          meetings and make better use of their teams' time and energy. But ultimately, we couldn't find those motivated
          people.
        </p>
        <p>
          We've learned a lot--about meetings, about the people who conduct them, about the opportunities for and
          limitations of applying behavioral science to product design, and about the roller-coaster ride that is
          founding a startup. We're hoping to share some of that insight over time; if you're interested, follow
          <a href="https://dfeldman.medium.com">Dave on Medium.</a>
        </p>
        <h3>Thank you.</h3>
        <p>
          Even a four-person startup can't exist without a lot of people. Thank you to our fantastic advisors, Dr.
          Ashley Whillans and Alex Frieder, for being far more involved and engaged than we had any right to expect. To
          our investors-- MaC, Reach, Newark, Aaron, Chrix, Devin, and Zac--for their ongoing support and advice,
          including and especially when times got tough. To our spouses and pets and families and friends for being
          there, and putting up with us through the ups and downs and utter craziness of early-stage startup life--and
          for not freaking out when we said we might want to do it again sometime. And to you: whether this is your
          first visit to our website or you're an active Miter user and feedbacker. We made this for you, and we thank
          you for giving it a chance. We'll see you the next time around.
        </p>
        <p>--Alejandro, Damian, Dave, &amp; Nico</p>
      </ProseModule>
    </Layout>
  );
};

export default IndexPage;
